import { computed, inject, Injectable, signal } from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";


/**
 * Service to handle navigation back to parent route.
 * Provides a method to get the parent link for navigation.
 * Useful when navigating back from a detail or subpage to its parent page.
 *
 * @example
 * ```typescript
 * import { Component, inject } from '@angular/core';
 * import { NavigationService } from './epg-modern-layout-navigation.service';
 *
 * @Component({
 *   selector: 'app-my-component',
 *   template: `
 *     <button (click)="navigateBack()">Go Back</button>
 *   `
 * })
 * export class MyComponent {
 *   navigationService = inject(NavigationService);
 *   
 *   navigateBack() {
 *     const parentLink = this.navigationService.back();
 *     // Navigate to the parentLink using your router
 *     this.router.navigate([parentLink]);
 *   }
 * 
 *   showBackButton() {
 *     const parentLink = this.navigationService.parentPageLink();
 *     return this.router.isActive('/home', {
 *         paths: 'exact', queryParams: 'exact', fragment: 'exact', matrixParams: 'ignored'
 *      });
 *   }
 * 
 * }
 * ```
 */

@Injectable({providedIn: 'root'})
export class NavigationService {

    private paths: string[] = [];
    private router = inject(Router);
    private previousUrl = signal('');
    private activeRoute = inject(ActivatedRoute);
    private parentPageRoute =  this.activeRoute.snapshot.firstChild;
    private subMenuName = signal<string | null>(null);


    /** 
    * Use parentPageLink to link back to parent page. This can be usefull when 
    * navigation from child route to go back to parent route
    * 
    * @example
    * 
    this.router.isActive(
      navigationService.parentLink(), 
      {
          paths: 'exact',
          queryParams: 'exact',
          fragment: 'exact',
          matrixParams: 'ignored',
      });
    *
    */
    public parentPageLink = signal<string | undefined>(undefined);
    public selectedSubMenu = computed( () => this.subMenuName );
  
    
    constructor() {

      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
            this.paths = event.url.split('/');
            this.paths.shift();
            this.previousUrl.update(() => this.paths.pop() || '');

            this.parentPageLink.set(this.parentPageRoute?.routeConfig?.path )
        }
      });
    }
   
      /**
     * Navigates back to the previous URL or parent URL. Meaning from '/parent/detail' 
     * will root back to "/parent"
     *
     * @returns The previous URL as a string.  Returns an empty string if there is no previous URL.
     *
     * @example
     * // In your component:
     * this.navigationService.back(); // Navigates back to the previous URL
     */

    back(): string {
      return this.previousUrl();
    }
    
    setSelectedSubMenu(name: string) {
      this.subMenuName.set(name);
    }
}