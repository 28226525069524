{
  "uxlib": {
    "general": {
      "undo": "Ongedaan maken",
      "save": "Opslaan",
      "close": "Sluiten",
      "dontSave": "Niet opslaan",
      "cancel": "Annuleren",
      "closeConfirm": "Wil je echt afsluiten?",
      "saveConfirm": "Wilt u uw wijzigingen opslaan?",
      "lostModifications": "Uw wijzigingen gaan verloren.",
      "continue": "Volgende",
      "yes": "Ja",
      "no": "Nee",
      "add": "Toevoegen",
      "pleaseWait": "Verwerking wordt uitgevoerd. Even geduld a.u.b.",
      "delete": "Verwijderen",
      "requiredFields": "Verplichte velden",
      "new": "Nieuw",
      "thisApplication": "Deze applicatie",
      "previous": "Vorige",
      "next": "Volgende"
    },
    "input": {
      "iban": "IBAN",
      "bic": "BIC",
      "bban": "BBAN",
      "inss": "Rijksregisternummer",
      "co2": "g/km",
      "incorrectchar": "Verboden karakters",
      "datePlaceholder": "DD/MM/JJJJ",
      "rangeDatePlaceholder": "DD/MM/JJJJ-DD/MM/JJJJ",
      "monthPlaceholder": "MM/JJJJ",
      "multiSelectFilterPlaceHolder": "Zoeken",
      "multiSelectPlaceholder": "Kies",
      "multiSelectItemsLabel": "{0} items geselecteerd",
      "emptyFilterMessage": "Geen resultaten gevonden",
      "globalSearch": "Globaal zoeken",
      "rowsPerPage": "Rijen per pagina"
    },
    "errors": {
      "success": "Succes",
      "error": "Fout",
      "warning": "Waarschuwing",
      "info": "Info",
      "genericNotification": "Validatie mislukt.",
      "notificationPlural": "Gelieve {{nrOfErrors}} velden te controleren om verder te gaan.",
      "notificationSingular": "Gelieve 1 veld te controleren om verder te gaan.",
      "required": "Dit veld is verplicht",
      "inss": "Geen geldig rijksregisternummer",
      "requiredInssForBelgian": "Rijksregisternummer is verplicht voor Belgische onderdanen.",
      "iban": "Geen geldige IBAN",
      "bic": "Geen geldige BIC",
      "bban": "Geen geldige BBAN",
      "bbanMod97": "Geen geldige BBAN",
      "email": "Geen geldig e-mailadres",
      "emailAddressAlreadyExists": "Het e-mailadres bestaat al.",
      "min": "Waarde kan niet lager zijn dan {{min}}. Was '{{actual}}'",
      "max": "Waarde kan niet hoger zijn dan {{max}}. Was '{{actual}}'",
      "dateFormat": "ongeldig formaat",
      "dateRangeFormat": "ongeldig formaat",
      "monthFormat": "ongeldig formaat",
      "maxdate": "Datum moet vóór maximumdatum zijn",
      "mindate": "Datum moet na minimumdatum zijn",
      "futureDate": "Het rijksregisternummer bevat een geboortedatum in de toekomst. Dit is niet mogelijk.",
      "maxlength": "Waarde kan niet langer zijn dan {{requiredLength}} karakters",
      "minlength": "Waarde kan niet korter zijn dan {{requiredLength}} karakters",
      "pattern": "Input voldoet niet aan het verwachte patroon",
      "payforceCharacter": "Teken '{{char}}' is niet toegelaten.",
      "payforceCharacters": "Tekens '{{chars}}' zijn niet toegelaten.",
      "minimumDateError": "Begin- en einddatum moeten binnen de afgelopen 3 jaar liggen"
    },
    "portalBar": {
      "selectPU": "Selecteer een dossier",
      "noResults": "Geen resultaat gevonden",
      "payrollUnit": "Dossier",
      "enterprise": "Onderneming",
      "min3char": "min. 3 kar.",
      "logout": "Uitloggen",
      "title": "Mijn Partena Professional",
      "logout2": "Afmelden",
      "confirmTitle": "U staat op het punt uit te loggen.",
      "cancel": "Annuleren",
      "confirmMessage": "U zal worden doorgestuurd naar de commerciële webiste van Partena Professional.",
      "languages": {
        "en": "English",
        "fr": "Français",
        "nl": "Nederlands"
      }
    },
    "plainText": {
      "noIE": {
        "title": "IE browser niet ondersteund",
        "message": "{{app}} ondersteunt Internet Explorer niet.\n Gelieve Google Chrome, Microsoft Edge of Safari\n te gebruiken.",
        "firstLine": "{{app}} ondersteunt Internet Explorer niet.",
        "secondLine": "Gelieve Google Chrome, Microsoft Edge of Safari te gebruiken."
      }
    },
    "disclaimer": {
      "title": "Servicevoorwaarden",
      "accept": "Aanvaarden",
      "decline": "Weigeren",
      "print": "Afdrukken"
    },
    "table": {
      "actions": "Acties",
      "export": "Exporteren",
      "filters": "Filter",
      "search": "Zoeken",
      "clearFilters": "Filters wissen",
      "applyFilters": "Filters toepassen"
    }
  },
  "primeng": {
    "dayNames": [
      "Zondag",
      "Maandag",
      "Dinsdag",
      "Woensdag",
      "Donderdag",
      "Vrijdag",
      "Zaterdag"
    ],
    "dayNamesShort": ["Zon", "Maa", "Din", "Woe", "Don", "Vrij", "Zat"],
    "dayNamesMin": ["Zo", "Ma", "Di", "Wo", "Do", "Vr", "Za"],
    "monthNames": [
      "Januari",
      "Februari",
      "Maart",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Augustus",
      "September",
      "Oktober",
      "November",
      "December"
    ],
    "monthNamesShort": [
      "jan",
      "feb",
      "mrt",
      "apr",
      "mei",
      "jun",
      "jul",
      "aug",
      "sep",
      "okt",
      "nov",
      "dec"
    ],
    "today": "Vandaag",
    "clear": "Wissen",
    "firstDayOfWeek": 1
  }
}
