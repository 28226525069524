import { inject, Injectable, input, signal } from '@angular/core';
import { EpgStatusDialogComponent } from './epg-status-dialog.component';
import { EpgDialogService } from '@epg-apps/epg-dialog';

export interface EpgStatusDialog {
  title: string;
  statusCode: number | string;
  htmlContent?: string;
  dismissLabel?: string;
}

export interface EpgStatusDialogConfig {
  width?: string;
  maxWidth?: string;
  minWidth?: string;
  height?: string;
  maxHeight?: string;
  minHeight?: string;
}

@Injectable({
  providedIn: 'root',
})
export class EpgStatusDialogService {
  private epgDialogService = inject(EpgDialogService);

  private config = signal<EpgStatusDialogConfig>({
    width: '100%',
    maxWidth: '450px',
  });

  setConfig(config: EpgStatusDialogConfig) {
    this.config.update(currentConfig => ({...currentConfig, config}));
  }

  open(content?: EpgStatusDialog) {
    return this.epgDialogService.open(EpgStatusDialogComponent, {
      ...this.config(),
      data: {
        title: content?.title,
        statusCode: content?.statusCode,
        dismissLabel: content?.dismissLabel,
        htmlContent: content?.htmlContent || `<p>If this problem persists, please <a href="https://partena-supportservices.topdesk.net/tas/public/ssp/">contact topdesk</a>.</p>`,
      },
    });
  }
}
