import { inject, Injectable } from '@angular/core';
import { Dialog } from '@angular/cdk/dialog'
import { ComponentType } from '@angular/cdk/portal';
import { EpgDialogConfig } from './epg-dialog-config.model';

/**
 * Service for opening modal dialogs.
 * @author Ryan Antenor <ryan.antenor@easypay-group.com>
 *
 * @example
 * ```typescript
 * // In your component:
 * import { EpgDialogService } from '@your-library/shared/services/dialog';
 * import { MyDialogComponent } from './my-dialog.component';
 * import { inject } from '@angular/core';
 *
 * private dialogService = inject(EpgDialogService);
 *
 * openDialog() {
 *   const dialogRef = this.dialogService.open(MyDialogComponent, {
 *     width: '500px',
 *     data: { message: 'Hello from parent!' }
 *   });
 *  
 *   // Called after dialog has been closed
 *   dialogRef.closed.subscribe( data => {
 *      console.log('dialog data: ', data)
 *   })
 * }
 * ```
 *
 * @example
 * ```typescript
 * // In your component:
 * import { EpgDialogService } from '@your-library/shared/services/dialog';
 * import { MyDialogComponent } from './my-dialog.component';
 * import { inject } from '@angular/core';
 * 
 * 
 *  private dialogService = inject(EpgDialogService);
 *
 * openDialog() {
 *   this.dialogService.open(MyDialogComponent);
 * }
 * ```
 *  * @example
 * ```typescript
 * // In your dialog component:
 * import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
 * import { Component, inject } from '@angular/core';
 *
 * @Component({
 *   selector: 'my-dialog',
 *   template: `
 *     <div>
 *       <p>{{ data.message }}</p>
 *       <button (click)="close()">Close</button>
 *     </div>
 *   `,
 * })
 * export class MyDialogComponent {
 *   public data = inject(DIALOG_DATA);
 *   private dialogRef = inject(DialogRef);
 *
 *   close() {
 *     this.dialogRef.close('Dialog closed!');
 *   }
 * }
 *
 */

@Injectable({
  providedIn: 'root'
})
export class EpgDialogService {
  private dialog = inject(Dialog);

  /**
   * Opens a modal dialog containing the given component.
   * @param component Type of the component to load into the dialog.
   * @param config Extra configuration options.
   * @returns Reference to the newly-opened dialog.
   */

  public open<C>(component: ComponentType<C>, dialogConfig?: EpgDialogConfig) {
    return this.dialog.open(component, {
      width: "250px",
      panelClass: "epg-dialog",
      ...dialogConfig
    });
  }

}
