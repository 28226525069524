import { Component } from '@angular/core';
import {RouterOutlet} from '@angular/router';

@Component({
  standalone: true,
  imports: [RouterOutlet],
  selector: 'cdh-root',
  template: '<router-outlet />'
})
export class AppComponent {
  title = 'cdh-new';
}
