import { trigger, transition, style, query, animateChild, group, animate, state } from "@angular/animations";

// export const slideInAnimation =
//   trigger('routeAnimations', [
//     transition('* <=> *', [
//       style({ position: 'relative' }),
//       query(':enter, :leave', [
//         style({
//           position: 'absolute',
//           top: 0,
//           left: 0,
//           width: '100%'
//         })
//       ]),
//       query(':enter', [
//         style({ left: '-100%' })
//       ], { optional: true }),
//       query(':leave', animateChild(), { optional: true }),
//       group([
//         query(':leave', [
//           animate('300ms ease-out', style({ left: '100%' }))
//         ], { optional: true }),
//         query(':enter', [
//           animate('300ms ease-out', style({ left: '0%' }))
//         ], { optional: true }),
//       ]),
//     ]),
//     transition('* <=> *', [
//       style({ position: 'relative' }),
//       query(':enter, :leave', [
//         style({
//           position: 'absolute',
//           top: 0,
//           left: 0,
//           width: '100%'
//         })
//       ], { optional: true }),
//       query(':enter', [
//         style({ left: '-100%' })
//       ], { optional: true }),
//       query(':leave', animateChild(), { optional: true }),
//       group([
//         query(':leave', [
//           animate('200ms ease-out', style({ left: '100%', opacity: 0 }))
//         ], { optional: true }),
//         query(':enter', [
//           animate('300ms ease-out', style({ left: '0%' }))
//         ], { optional: true }),
//         query('@*', animateChild(), { optional: true })
//       ]),
//     ])
//   ]);


// export const routeSlideAnimation = trigger(
//     'routeAnimations', [
//         transition('* <=> *', [
//                 query(':enter, :leave', [
//                     style({
//                         position: 'absolut',
//                         width: '100%',
//                         left: 0,
//                         top: 0,
//                     })
//                 ]),
//                 query(':enter', [
//                     style({ left: '-100%' })
//                 ]),
//                 query(':leave', animateChild(), { optional: true }),

//                 // group([
//                 //     query(':leave', [
//                 //         animate('600ms ease', style({left:'100%'}))
//                 //     ]),
//                 //     query(':enter', [
//                 //         animate('600ms ease', style({left:'0%'}))
//                 //     ])
//                 // ])
//             ]
//         )
//     ]
// )

// This works
export const routeSlideAnimation  =  trigger('routeAnimations', [
    transition('* <=> *', [
      style({ transform: 'translateX(100%)', opacity: 0 }),
      animate('500ms ease-in-out', style({ transform: 'translateX(0)', opacity: 1 }))
    ])
  ])

// This works
export const slideLeftAndRight = trigger('routeAnimations', [
    transition('* <=> *', [
      style({ position: 'relative' }),
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%'
        })
      ], { optional: true }),
      query(':enter', [
        style({ left: '-100%' })
      ], { optional: true }),
      group([
        query(':leave', [
          animate('600ms ease-out', style({ left: '100%' }))
        ], { optional: true }),
        query(':enter', [
          animate('600ms ease-out', style({ left: '0%' }))
        ], { optional: true })
      ]),
    ]),
  ]);

export const routePageAnimationLeftToRightAnimation = trigger('routeAnimation', [
    transition('* <=> *', [
      style({ position: 'relative' }),
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          opacity: 0,
        })
      ], { optional: true }),
      query(':enter', [
        style({ left: '-100%' })
      ], { optional: true }),
      group([
        query(':leave', [
          animate('600ms ease-out', style({ left: '100%', opacity: 0, }))
        ], { optional: true }),
        query(':enter', [
          animate('600ms ease-out', style({ left: '0%', opacity: 1,}))
        ], { optional: true })
      ]),
    ]),
  ]);


export const fadeInBottomAnimation = trigger('routeAnimation', [
  transition('* <=> *', [
    style({ 
      position: 'relative',
      width: '100%',

    }),
    query(':enter, :leave', [
      style({
        opacity: 1,
        // transform: 'translateY(100%)'
      })
    ], { optional: true}),
    query(':enter', [
      style({ opacity: '0%' })
    ], { optional: true }),
    group([
      query(':leave', [
        // style({ opacity: 0 })
        animate('600ms ease-in-out', style({  opacity: 0, }))
      ], { optional: true }),
      query(':enter', [
        // style({ opacity: 1 })
        animate('600ms ease-in-out', style({ opacity: 1,}))
      ], { optional: true })
    ]),
  ])
])


export const slideInAnimation  = trigger('routeAnimation', [
  state('visible', style({ opacity: 1 })),
  state('hidden', style({ opacity: 0 })),
  transition('visible <=> hidden', animate('1s ease-in-out')),
]);


const fadingIntro = query(':enter', [
  style({
    opacity: 0
  })
], { optional: true});

const fadingOutro = query(':leave', [
  style({
    opactiy: 0
  })
],{ optional: true})

export const pageFadingIn = trigger('routeAnimation', [
  transition('* <=> *', [

    style({ position: 'relative' }),
   
    // query(':enter, :leave', [
    //   style({
    //       opactiy: 0,
    //       // position: 'absolute',
    //       // top: 0,
    //       // left: 0,
    //       // width: '100%'
    //   })
    // ], { optional: true }),


    fadingIntro,
    fadingOutro,
    group([
      query(':leave', [animate('500ms ease-in-out', style({  opacity: 0 }))],{ optional: true}),
      query(':enter', [animate('500ms ease-in-out', style({  opacity: 0 }))],{ optional: true})
     ]),
  ])
])
  
