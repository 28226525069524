
<div class="error-dialog">
    <div class="header">
        <div class="logo"></div>
        <div class="title"> {{ statusCode }} - {{ title }}</div>
    </div>
    <div class="content" [innerHTML]="htmlContent"></div>
    <div class="actions">
        <button epg-button (click)="dismiss()" color="primary"> {{ dismissLabel || 'Close ' | titlecase}} </button>
    </div>
</div>
