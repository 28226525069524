import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from '@epg-apps/cdh-environments';
import { inject } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { AuthenticationService } from './authentication-service';

export function authenticationTokenInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  //TODO remove hardcoded token once able to login, this is for testing purposes
  const cookieService = inject(CookieService);
  const router = inject(Router);
  const authenticationService = inject(AuthenticationService);
  const jwtToken = cookieService.get(
    AuthenticationService.mapCookieNameToEnvironment(AuthenticationService.EP_TOKEN)
  );
  const tokenizedRequest = req.clone({
    setHeaders: {
      Authorization: 'Bearer ' + jwtToken,
      'Ocp-Apim-Subscription-Key': environment.apimSubscriptionKey,
    },
  });
  return next(tokenizedRequest)
  .pipe(
    catchError((error: HttpErrorResponse) => {
      if (error.status === 401) {
        authenticationService.login(router.url);
      }
      return throwError(() => error);
    })
  );
}


//TODO For reference when new function based interceptor needs to be extended for actually logging in, clean up once that's done
// @Injectable()
// export class AuthenticationTokenInterceptor implements HttpInterceptorFn {
//   private readonly cookieService = inject(CookieService);
//   private readonly router = inject(Router);
//   private readonly authenticationService = inject(AuthenticationService);
//
//   intercept(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
//     if (environment.bypassSecurity) return next.handle(req);
//     const jwtToken = this.cookieService.get(
//       AuthenticationService.mapCookieNameToEnvironment(
//         AuthenticationService.EP_TOKEN
//       )
//     );
//     console.log("called");
//     const tokenizedRequest = req.clone({
//       setHeaders: {
//         Authorization: 'Bearer ' + jwtToken,
//         'Ocp-Apim-Subscription-Key': environment.apimSubscriptionKey,
//       },
//     });
//     return next.handle(tokenizedRequest)
//     .pipe(
//       catchError((error: HttpErrorResponse) => {
//         if (error.status === 401) {
//           this.authenticationService.login(this.router.url);
//         }
//         return throwError(() => error);
//       })
//     );
//   }
// }
