<pux-customerbar 
  [title]="portalBarTitle"
  [customizableButtons]="true">

  @if(toolbarConfig?.downloadable) {
    @if(isDownloading()) {
      <pux-customerbar-button icon="fa-regular fa-spinner fa-spin" />
    } @else if (downloadCompleted()) {
      <pux-customerbar-button icon="fa-solid fa-download" (clicked)="openDownloadedFile()" />
    } @else if(downloadCompleted() === false) {
      <pux-customerbar-button  icon="fa-regular fa-download" (clicked)="onDownload()" />
    }
  }

</pux-customerbar>

@if(applicationName) {
  <div class="app-menu">
    @for(menu of applicationMenuBar; track menu) {
      <a [routerLink]="menu.path" [routerLinkActive]="'active'">
        {{ menu.alias || menu.label }}
      </a>
    }
  </div>
}
<div class="page-wrapper">
  <div class="page-section">
    @if(showBackPageLink()) {
    <a (click)="navigateBack()">
      <i class="fas fa-arrow-left"></i> {{ navigateToPreviousLabel ?? 'Back to previous page'}}
    </a>
    }
    <router-outlet />
  </div>

  <!-- TODO: Revisit for future changes when the navigation bar is completed -->
  <!-- <div class="page-container"> 
          @if (showBackPageLink) {
                <div class="back-action">
                    <a [routerLink]="navigateBack()">
                        <i class="fas fa-arrow-left"></i> {{ backLinkToParentTitle || pageLinkTitle ??   'Back to previous page'}}
                    </a>
                </div>
            }  -->
  <!-- <div class="page-content">
            <router-outlet />
        </div> -->
  <!-- </div> -->

  <!-- TODO: animated page -->

  <!-- <div class="page-section"  [@routeAnimation]="prepareRoute(outlet)">
        <router-outlet #outlet="outlet"/>
    </div> -->

</div>