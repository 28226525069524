import { computed, inject, Injectable, signal } from '@angular/core';
import { environment } from '@epg-apps/cdh-environments';
import { HttpClient } from '@angular/common/http';
import { ConnectionStatusRestructured, DetailedEnvironment, DetailedEnvironmentRestructured, Environment, EnvironmentState, ProductRestructured } from '@epg-apps/cdh-models';
import { map, take } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {
  private static readonly ENVIRONMENT_CDH_API = environment.cdhApi + 'environments';
  private http = inject(HttpClient);

  private environmentState = signal<EnvironmentState>({
    selectedUserEnvironments: [],
    detailedEnvironment: undefined
  });

  selectedUserEnvironments = computed(() => this.environmentState().selectedUserEnvironments);
  detailedEnvironment = computed(() => this.environmentState().detailedEnvironment);

  public static toEnvironmentDetailUI(detailedEnvironment: DetailedEnvironment): DetailedEnvironmentRestructured {
    const systemInfo = detailedEnvironment.systemInfo;
    const technicalInfo = detailedEnvironment.softwareInfo.technicalInfo;
    const javaEnvironment = technicalInfo.cloudLinkInfo.javaEnvironment;
    const functionalInfo = detailedEnvironment.softwareInfo.functionalInfo;
    const menuInfo = functionalInfo.menuInfo;
    return {
      userEnvironment: {
        ip: systemInfo.ip,
        mac: systemInfo.mac,
        hostname: systemInfo.hostname,
        baseURL: technicalInfo.baseURL,
        webServer: technicalInfo.webServer,
        tomcatVersion: technicalInfo.tomcatVersion,
        cobolVersion: technicalInfo.cobolInfo.version,
        cloudLinkVersion: technicalInfo.cloudLinkInfo.version
      },
      technicalInfo: {
        userName: technicalInfo.userName,
        homeDirectory: technicalInfo.homeDirectory,
        workingDirectory: technicalInfo.workingDirectory
      },
      systemInfo: {
        totalMemory: systemInfo.totalMemory,
        availableMemory: systemInfo.availableMemory
      },
      javaInfo: {
        version: javaEnvironment.version,
        vendor: javaEnvironment.vendor,
        installationDirectory: javaEnvironment.installationDirectory,
        processors: javaEnvironment.processors,
        maxMemory: javaEnvironment.maxMemory,
        totalMemory: javaEnvironment.totalMemory
      },
      connectionStatus: technicalInfo.cloudLinkInfo.connectionResults.reduce((accumulatorObject, connectionResult) => {
        accumulatorObject[connectionResult.name] = connectionResult.success;
        return accumulatorObject;
      }, {} as ConnectionStatusRestructured),
      isConnectionHealthy: detailedEnvironment.isConnectionHealthy,
      products: functionalInfo.products
      .reduce((accumulatorObject, product) => {
        accumulatorObject[product.name] = product.version;
        return accumulatorObject;
      }, {} as ProductRestructured),
      environmentType: {
        designation: functionalInfo.designation.enumName
      },
      releaseInfo: {
        folder: functionalInfo.providedReleaseFolder,
        configNumber: functionalInfo.providedConfigNumber
      },
      menuInfo: {
        isNewMenu: menuInfo.isNewMenu,
        totalUsers: menuInfo.totalUsers,
        masterF1Users: menuInfo.masterF1Users,
        convertedUsers: menuInfo.convertedUsers
      }
    };
  }

  public getEnvironmentsByCustomerId(customerId: string) {
    const options = { params: { customerId } };
    const url = EnvironmentService.ENVIRONMENT_CDH_API;
    return this.http.get<Environment[]>(url, options);
  }

  public setSelectedUserEnvironments(environments: Environment[]): void {
    this.environmentState.update(state => {
      return {
        ...state,
        selectedUserEnvironments: environments
      };
    });
  }

  public setDetailedEnvironment(detailedEnvironmentUI: DetailedEnvironmentRestructured): void {
    this.environmentState.update(state => ({
      ...state,
      detailedEnvironment: detailedEnvironmentUI
    }));
  }

  public getDetailedEnvironmentById(id: string) {
    const environmentApiUrl = EnvironmentService.ENVIRONMENT_CDH_API + '/' + id;
    return this.http.get<DetailedEnvironment>(environmentApiUrl)
    .pipe(take(1),
      map((detailedEnvironment: DetailedEnvironment): DetailedEnvironmentRestructured => {
        return EnvironmentService.toEnvironmentDetailUI(detailedEnvironment);
      })
    );
  }
}
