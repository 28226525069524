import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { CustomerService } from '@epg-apps/customer-api';
import { combineLatest, Observable, tap } from 'rxjs';
import { ContactDto, CustomerDetail, Environment, PageDto } from '@epg-apps/cdh-models';
import { EnvironmentService } from '@epg-apps/environment-api';
import { CdhContactApiService } from '@epg-apps/cdh-contact-api';

@Injectable({
  providedIn: 'root'
})
export class CustomerDetailResolver implements Resolve<[CustomerDetail, Environment[], PageDto<ContactDto>]> {
  customerService = inject(CustomerService);
  environmentService = inject(EnvironmentService);
  contactsService = inject(CdhContactApiService);

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<[CustomerDetail, Environment[], PageDto<ContactDto>]> {
    const customerId = route.params['customerId'];
    return combineLatest([
      this.customerService.getCustomerDetails(customerId),
      this.environmentService.getEnvironmentsByCustomerId(customerId),
      this.contactsService.getContactsByCustomerId(customerId)

    ]).pipe(
      tap(([customerDetail, environments, contacts]) => {
        this.customerService.setCustomerDetails(customerDetail)
        this.environmentService.setSelectedUserEnvironments(environments)
        this.contactsService.setContacts(contacts)
      })
    )
  }
}
