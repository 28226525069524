import { Route } from "@angular/router";
import { EpgModernLayoutComponent } from "@epg-apps/epg-modern-layout";

/**
 * A function to generate routes for the application.
 * This function takes an array of routes as input and returns an array of routes
 * that includes a main route with the EpgModernLayoutComponent as its component.
 * The main route has children routes that are passed as input.  The children routes
 * can enable `navigateToPreviousPage` to go to the previous page and/or add
 * `navigateToPreviousLabel` as a string.
 * @param routes - An array of routes to be added as children of the main route.
 * @returns An array of routes including the main route and its children.
 *
 * @note Do not forget to add  `withComponentInputBinding()`
 *
 * @example
 * // In your app.config.ts:
 * import { provideRouter } from '@angular/router';
 * import { epgRoutes } from '@epg-apps/epg-routes';
 *
 * const routes = [
 *   { path: 'home', component: HomeComponent },
 *   { path: 'about', component: AboutComponent },
 *   { path: 'product', component: ProductComponent,
 *        children: [
 *       { path: ':id', component: ProductDetailComponent,
 *          data: {
 *              navigateToPreviousPage: true,
 *              navigateToPreviousLabel: 'Go back to product page'
 *          }
 *       }
 *    ] },
 * ];
 *
 * export const appConfig: ApplicationConfig = {
 *   providers: [
 *     provideRouter(epgRoutes(routes),  withComponentInputBinding()),
 *   ],
 * };
 */
export function epgRoutes(routes: Route[]) {
    const mainRouting: Route = {
        path: '',
        component: EpgModernLayoutComponent,
        children: routes
    }

    return [
        mainRouting,
    ];
}
