import {HttpEvent, HttpHandlerFn, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs";

export function defaultCdhHeadersInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  const defaultHeaders = req.clone({
    setHeaders: {
      'Content-Type': 'application/json',
      Version: 'v1.0',
    },
  });
  return next(defaultHeaders);
}
