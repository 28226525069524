
@import '../../../../../../assets/themes/src/lib/epg-themes/color';

.page-wrapper {
    padding: 1.5rem;
    // padding-top: 24px;
    // padding-bottom: 48px;
    border-style: solid;
    border-color: #E6F5FB; /* red */
    border-width:  1.5rem;
    border-radius:  1.5rem;
    background-color: #F8F9FA;
    margin: 0 1.5rem;
    max-width: 1920px; // Setup limitation based on partena ux logic ( Partena - Bare, Sebastien)
 
    @media (min-width: 1920px) {
        max-width: 1920px;
        margin: 0 auto;
    }

    // Box shadow
    -webkit-box-shadow: 0px 12px 29px 0px rgba(8, 0, 112, 0.12);;
    -moz-box-shadow: 0px 12px 29px 0px rgba(8, 0, 112, 0.12);;
    box-shadow: 0px 12px 29px 0px rgba(8, 0, 112, 0.12);;

    
}

.page-section {
    position: relative;
}

.page-container {
    
    padding: 24px;
    // background-color: rgba(8, 0, 112, 0.12);
    background-color: #FAFAFF;
    border-radius: 15px;
    font-size: 2em;

    -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.12);
}

.page-content {
    // -webkit-box-shadow: 0px 0px 0px 12px rgba(8,0,112,0.12);
    // -moz-box-shadow: 0px 0px 0px 12px rgba(8,0,112,0.12);
    // box-shadow: 0px 0px 0px 12px rgba(8,0,112,0.12);

    border-radius: 10px;

    padding: 24px;
    background-color: white;
}

.back-action {
    padding-bottom: 24px;
}

.active {
    background-color: $secondary-color;
    border-radius: 15px;
    color: #FAFAFF;
}

.app-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;


    a {
        padding: 5px 15px;
        font-weight: bolder;
        margin: 16px 0px;
        color: $secondary-color;

       
        &.active {
            color: white;
        }
    }
}

