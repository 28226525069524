{
  "uxlib": {
    "general": {
      "undo": "Défaire",
      "save": "Sauvegarder",
      "close": "Fermer",
      "dontSave": "Pas sauvegarder",
      "cancel": "Annuler",
      "closeConfirm": "Voulez-vous vraiment fermer?",
      "saveConfirm": "Voulez-vous enregistrer vos modifications?",
      "lostModifications": "Vos modifications seront perdues.",
      "continue": "Continuer",
      "yes": "Oui",
      "no": "Non",
      "add": "Ajouter",
      "pleaseWait": "Traitement en cours, merci de patienter.",
      "delete": "Supprimer",
      "requiredFields": "Champs obligatoires",
      "new": "Nouveau",
      "thisApplication": "Cette application",
      "previous": "Précédent",
      "next": "Suivant"
    },
    "input": {
      "iban": "IBAN",
      "bic": "BIC",
      "bban": "BBAN",
      "inss": "Numéro national",
      "co2": "g/km",
      "incorrectchar": "Caractères interdits",
      "datePlaceholder": "JJ/MM/AAAA",
      "rangeDatePlaceholder": "JJ/MM/AAAA-JJ/MM/AAAA",
      "monthPlaceholder": "MM/AAAA",
      "multiSelectFilterPlaceHolder": "Chercher",
      "multiSelectPlaceholder": "Choisir",
      "multiSelectItemsLabel": "{0} éléments sélectionnés",
      "emptyFilterMessage": "Aucun résultat trouvé",
      "globalSearch": "Recherche globale",
      "rowsPerPage": "Lignes par page"
    },
    "errors": {
      "success": "Succès",
      "error": "Erreur",
      "warning": "Avertissement",
      "info": "Info",
      "genericNotification": "Validation infructueuse.",
      "notificationSingular": "Il y a 1 champ à vérifier avant de pouvoir continuer.",
      "notificationPlural": "Il y a {{nrOfErrors}} champs à vérifier avant de pouvoir continuer.",
      "required": "Ce champ est obligatoire",
      "inss": "Le numéro national est invalide",
      "requiredInssForBelgian": "Le numéro national est obligatoire pour les ressortissants belges.",
      "iban": "IBAN invalide",
      "bic": "BIC invalide",
      "bban": "BBAN invalide",
      "bbanMod97": "BBAN invalide",
      "email": "Adresse e-mail invalide",
      "emailAddressAlreadyExists": "Cette adresse e-mail existe déjà.",
      "min": "La valeur ne peut pas être inférieure à {{min}}. Était '{{actual}}'",
      "max": "La valeur ne peut pas être supérieure à {{max}}. Était '{{actual}}'",
      "dateFormat": "format invalide",
      "dateRangeFormat": "format invalide",
      "monthFormat": "format invalide",
      "maxdate": "La date doit être antérieure à la date maximum",
      "mindate": "La date doit être postérieure à la date minimum",
      "futureDate": "Le numéro national contient une date de naissance dans le futur. Ce n'est pas possible.",
      "maxlength": "La valeur ne peut pas dépasser {{requiredLength}} caractères",
      "minlength": "La valeur ne peut être moins longue que {{requiredLength}} caractères",
      "pattern": "La valeur ne correspond pas au modèle requis",
      "payforceCharacter": "Le caractère '{{char}}' n'est pas autorisé.",
      "payforceCharacters": "Les caractères '{{chars}}' ne sont pas autorisés.",
      "minimumDateError": "La date de début et de fin doit être une date comprise dans les 3 dernières années"
    },
    "portalBar": {
      "selectPU": "Sélectionnez un dossier",
      "noResults": "Aucun résultat trouvé",
      "payrollUnit": "Dossier",
      "enterprise": "Entreprise",
      "min3char": "min. 3 car.",
      "logout": "Déconnexion",
      "title": "My Partena Professional",
      "logout2": "Déconnexion",
      "confirmTitle": "Vous êtes sur le point de vous déconnecter.",
      "cancel": "Annuler",
      "confirmMessage": "Vous allez être redirigé vers le site commercial de Partena Professional.",
      "languages": {
        "en": "English",
        "fr": "Français",
        "nl": "Nederlands"
      }
    },
    "plainText": {
      "noIE": {
        "title": "Le navigateur IE n'est pas pris en charge",
        "message": "{{app}} ne soutiens pas Internet Explorer.\n Veuillez utiliser Google Chrome, Microsoft Edge ou Safari\n pour utiliser l'application.",
        "firstLine": "{{app}} ne soutiens pas Internet Explorer.",
        "secondLine": "Veuillez utiliser Google Chrome, Microsoft Edge ou Safari pour utiliser l'application."
      }
    },
    "disclaimer": {
      "title": "Conditions générales d'utilisations",
      "accept": "Accepter",
      "decline": "Refuser",
      "print": "Imprimer"
    },
    "table": {
      "actions": "Actions",
      "export": "Exporter",
      "filters": "Filtrer",
      "search": "Rechercher",
      "clearFilters": "Effacer les filtres",
      "applyFilters": "Appliquer les filtres"
    }
  },
  "primeng": {
    "dayNames": [
      "Dimanche",
      "Lundi",
      "Mardi",
      "Mercredi",
      "Jeudi",
      "Vendredi",
      "Samedi"
    ],
    "dayNamesShort": ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
    "dayNamesMin": ["Di", "Lu", "Ma", "Me", "Je", "Ve", "Sa"],
    "monthNames": [
      "Janvier",
      "Février",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Août",
      "Septembre",
      "Octobre",
      "Novembre",
      "Décembre"
    ],
    "monthNamesShort": [
      "janv",
      "févr",
      "mars",
      "avr",
      "mai",
      "juin",
      "juil",
      "août",
      "sept",
      "oct",
      "nov",
      "déc"
    ],
    "today": "Aujourd'hui",
    "clear": "Effacer",
    "firstDayOfWeek": 1
  }
}
