import { computed, inject, Injectable, signal } from '@angular/core';
import { environment } from '@epg-apps/cdh-environments';
import { HttpClient } from '@angular/common/http';
import { ContactDto, ContactState, PageDto } from '@epg-apps/cdh-models';

@Injectable({
  providedIn: 'root'
})
export class CdhContactApiService {

  private static readonly CONTACT_CDH_API = environment.cdhApi + 'contacts';
  http = inject(HttpClient);

  private contactsState = signal<ContactState>({
    contactPage: undefined
  });

  selectedCustomerContacts = computed(() => this.contactsState().contactPage?.content ?? []);

  public getContactsByCustomerId(customerId: string, pageNumber: number = 0) {
    const headerOptions = {
      params: {
        customerId: customerId,
        pageNumber: pageNumber
      }
    };
    return this.http.get<PageDto<ContactDto>>(CdhContactApiService.CONTACT_CDH_API, headerOptions);
  }

  public setContacts(contacts: PageDto<ContactDto>) {
    this.contactsState.update(state => ({
      ...state,
      contactPage: contacts
    }));
  }
}
