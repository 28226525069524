import { InjectionToken } from "@angular/core";

export interface AppMenuItemLink {
    path: string;
    label: string;
    icon?: string;
    alias?: string;
}

/**
 * Injection token to provide the configuration for the application menu.
 * This token should be used to define the structure and visibility of the 
 * application's menu bar.  It accepts an `AppMenuItemLink` object 
 * (or an array of `AppMenuItemLink` objects) for customization.
 *
 * @example
 * // In your app.config.ts or a module provider:
 * providers: [
 *   {
 *     provide: APP_MENU_CONFIG_TOKEN,
 *     useValue: [{ path: '/home', label: 'Home', icon: 'home-icon', alias: 'My Homepage' }] // Or an array of these
 *   }
 * ]
 * 
 * or 
 *  * providers: [
 *   {
 *     provide: APP_MENU_CONFIG_TOKEN,
 *     useValue: featureAppRoutes
 *   }
 * ]
 * 
 */
export const APP_MENU_CONFIG_TOKEN = new InjectionToken<AppMenuItemLink[]>('APP_MENU_CONFIG_TOKEN');

export type AppToolBarButtonType = {
    icon: string;
}

export interface AppToolBarDownloadType extends AppToolBarButtonType {
    downloadUrl: string;
    type: string;
}

export interface AppToolbarConfig {
    buttons?: AppToolBarDownloadType[]
    downloadable?: boolean;
    logoutUrl?: string;
}
export const APP_TOOLBAR_CONFIG_TOKEN = new InjectionToken<AppToolbarConfig>('APP_NAVIGATION_CONFIG_TOKEN');

/**
 * Injection token to provide the title for the "back to previous page" link.
 * This token should be used to configure the displayed text of the link 
 * that navigates the user back to the parent page.
 *
 * @example
 * // In the child component's providers:
 * providers: [
 *   { provide: APP_MENU_BACK_TO_PREVIOUS_TITLE_TOKEN, useValue: 'Return to Home' }
 * ]
 */
export const APP_MENU_BACK_TO_PREVIOUS_TITLE_TOKEN = new InjectionToken<string>('APP_MENU_BACK_TO_PREVIOUS_TOKEN');

/**
 * Injection token to provide the application's name.
 * This token should be used to configure the displayed name of the application.
 *
 * @example
 * // In your app.config.ts or a module provider:
 * providers: [
 *   { provide: APP_NAME_TOKEN, useValue: 'My Awesome App' }
 * ]
 */
export const APP_NAME_TOKEN = new InjectionToken<string>('APP_NAME_TOKEN');

/**
 * Injection token to provide the title for the portal bar.
 * This token should be used to configure the title displayed in the portal bar.  
 *
 * @example
 * // In your app.config.ts or a module provider:
 * providers: [
 *   { provide: PORTAL_BAR_TITLE_TOKEN, useValue: 'My Portal Title' }
 * ]
 */

export const PORTAL_BAR_TITLE_TOKEN = new InjectionToken<string>('PORTAL_BAR_TITLE_TOKEN');