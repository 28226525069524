import { Routes } from '@angular/router';
import { CustomerDetailResolver, DetailedEnvironmentResolver } from '@epg-apps/utils';

export const customerRoutes: Routes = [
  {
    path: 'customers/:customerId', loadComponent: () => import('@epg-apps/cdh-customer-detail').then(m => m.CdhCustomerDetailComponent), pathMatch: 'full',
    data: { navigateToPreviousPage: true },
    resolve: {
      data: CustomerDetailResolver
    }
  },
  {
    path: 'customers/:customerId/environments/:environmentId', loadComponent: () => import('@epg-apps/cdh-environment-detail').then(m => m.CdhEnvironmentDetailComponent),
    data: { navigateToPreviousPage: true },
    resolve: {
      data: DetailedEnvironmentResolver
    }
  },
  // TODO: need to be replaced by @Joren's version of CustomerOverviewComponent
  { path: 'customers', loadComponent: () => import('@epg-apps/customer-overview').then(m => m.CdhCustomerOverviewComponent), pathMatch: 'full' },
  { path: '', redirectTo: 'customers', pathMatch: 'full' }
];
