
// Reference - UXlib design _partena-color.paleltte.scss ($grey-list)
:root{
    --status-title-color:  #495057; // grey: 7
    --status-max-width: 700px;
    --status-title-border-bottom: 1px solid var(--primary-color);
}

.error-dialog {
    // background-color: white;
    width: 100%;
    max-width:var(--status-max-width);
    // margin: 1rem;
    padding: 30px 30px;
    // box-shadow: 0px 12px 29px 0px rgba(8, 0, 112, 0.12);

    .logo {
        width: 100%;
        display: block;
        background-image: url('/assets/images/logo-solid.png');
        background-repeat: no-repeat;
        background-position: center;
        // width: 300px;
        height: 70px;
        display: flex;
        justify-content: center;
        margin-bottom: 2rem;
    }

    .title {
        color: var(--status-title-color);
        font-size: 22px;
        font-family: 'Roboto';
        border-bottom: var(--status-title-border-bottom);
        background-size: cover;
        width: 100%;
        display: flex;
        margin-bottom: 2rem;
    }

    .content {
        line-height: 1.5em;
    }
    .actions {
        display: flex;
        // width: 100%;
        justify-content: center;
        justify-content: flex-end;
    }
} 