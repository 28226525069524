import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { EnvironmentService } from '@epg-apps/environment-api';
import { CustomerService } from '@epg-apps/customer-api';
import { combineLatest, Observable, tap } from 'rxjs';
import { CustomerDetail, DetailedEnvironmentRestructured } from '@epg-apps/cdh-models';

@Injectable({
  providedIn: 'root'
})
export class DetailedEnvironmentResolver implements Resolve<[CustomerDetail, DetailedEnvironmentRestructured]> {
  environmentService = inject(EnvironmentService);
  customerService = inject(CustomerService);

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<[CustomerDetail, DetailedEnvironmentRestructured]> {
    const customerId = route.params['customerId'];
    const environmentId = route.params['environmentId'];

    return combineLatest([
      this.customerService.getCustomerDetails(customerId),
      this.environmentService.getDetailedEnvironmentById(environmentId)
    ]).pipe(
      tap(([customerDetail, environmentDetail]) => {
        this.customerService.setCustomerDetails(customerDetail);
        this.environmentService.setDetailedEnvironment(environmentDetail);
      })
    );
  }
}
