import { Component, inject, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiEpgButtonDirective } from '@epg-apps/ui-button';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { EpgStatusDialog } from './epg-status-dialog.service';

@Component({
  selector: 'epg-status-dialog',
  imports: [CommonModule, UiEpgButtonDirective],
  templateUrl: './epg-status-dialog.component.html',
  styleUrl: './epg-status-dialog.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class EpgStatusDialogComponent {
  
  private dialogData = inject<EpgStatusDialog>(DIALOG_DATA);

  protected title: string = '';
  protected statusCode: number | string = '';
  protected htmlContent?: string = '';
  protected dismissLabel?: string = '';
  
  private statusDialogRef = inject(DialogRef);

  protected ngOnInit(): void {
     const { title, statusCode, htmlContent, dismissLabel } = this.dialogData;

     this.title =  title;
     this.statusCode = statusCode;
     this.htmlContent = htmlContent;
     this.dismissLabel = dismissLabel;
  }


  protected dismiss(): void {
    this.statusDialogRef.close();
  }
}
