import { HttpClient } from "@angular/common/http";
import { importProvidersFrom, inject } from "@angular/core";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TRANSLATION_PATH_TOKEN } from "./translation-path.token";
import { TranslationLocal } from '@epg-apps/util-translations';
export function factoryTranslateLoader(http: HttpClient) {
    const translationPath = inject(TRANSLATION_PATH_TOKEN, { optional: true});
    return new TranslateHttpLoader(http, `/${translationPath ?? 'i18n'}/`, '.json');
}

/**
 * A factory function that creates a TranslateHttpLoader instance.
 * so by default it will read translation json files from public folder at /i18n/
 * @returns A TranslateHttpLoader instance.
 */

export function provideTranslations() {
    return importProvidersFrom(
        TranslateModule.forRoot({
          defaultLanguage: TranslationLocal.EN,
          loader: {
            provide: TranslateLoader,
            useFactory: factoryTranslateLoader,
            deps: [HttpClient],
          },
        })
    )
}
