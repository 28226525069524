import { Injectable } from '@angular/core';
import { Downloader } from './downloader.interface';
import { Observable, of } from 'rxjs';
import { HttpEvent } from '@angular/common/http';
import { DownloadState } from './epg-download-center.service';

/**
 * This service provides a centralized way to handle downloads within the application.
 * It implements the `Downloader` interface and is designed to be used with configurations
 * provided through the `APP_TOOLBAR_CONFIG_TOKEN`.
 *
 * @implements {Downloader}
 * 
 * @dependencies `APP_TOOLBAR_CONFIG_TOKEN` - This service relies on a configuration
 * object provided through this injection token. This config should contain information
 * needed for download operations, such as file names, API endpoints, or other relevant parameters.
 * @property {ToolbarConfig} toolbarConfig - Config provided by APP_TOOLBAR_CONFIG_TOKEN
 * 
 * @author Ryan Antenor <ryan.antenor@easypay-group.com>
 * 
*/

@Injectable({
  providedIn: 'root'
})
export class EpgDownloadService implements Downloader{
  private downloadState: DownloadState = {
    downloading: false,
    progress: 0,
    error: null,
    isCompleted: false
  };

  download<T>(): Observable<HttpEvent<any>> {
    return of();
  }
}
