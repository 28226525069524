import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import {provideRouter, withComponentInputBinding} from '@angular/router';
import {APP_MENU_CONFIG_TOKEN, APP_NAME_TOKEN} from "@epg-apps/epg-modern-layout";
import {provideTranslations} from "@epg-apps/translation-provider";
import {provideHttpClient, withInterceptors} from "@angular/common/http";
import {epgRoutes} from "@epg-apps/epg-routes";
import {customerRoutes} from "@epg-apps/customer-routes";
import {authenticationTokenInterceptor, defaultCdhHeadersInterceptor, AuthenticationService} from "@epg-apps/utils";
import {provideAnimations} from "@angular/platform-browser/animations";
import {epgHttpErrorInterceptor} from "@epg-apps/epg-http-error-interceptor";

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(
      withInterceptors([defaultCdhHeadersInterceptor, authenticationTokenInterceptor, epgHttpErrorInterceptor])
    ),
    provideTranslations(),
    provideRouter(epgRoutes(customerRoutes), withComponentInputBinding()),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideAnimations(),
    AuthenticationService,
    { provide: APP_NAME_TOKEN, useValue: 'CDH' },
    {
      provide: APP_MENU_CONFIG_TOKEN,
      useValue: [
        { path: '/customers', label: 'Klanten', icon: 'fa-book-user' },
        // { path: '/employees', label: 'Werknemers', icon: 'fa-users' }
      ]
    }
  ],
};
